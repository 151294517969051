import './template1.scss';


/**template reference from career page. */
function Template1() {
    return (
        <>
            <div className='template-parent'>

                <section className='template-banner'>
                    <div>
                        <p className='template-banner-div'>

                        </p>
                    </div>
                </section>

                <section className='template-child-2'>
                    <h1></h1>
                    <p></p>
                </section>

                <section className='template-child-3'>
                    <div className='template-child3-content'>
                        <p></p>
                        <p></p>
                        <p></p>
                    </div>
                    <div className='template-child3-img'>

                    </div>
                </section>

                <section className='template-child-4'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </section>

                <section className='template-child-5'>
                    <div>

                    </div>
                </section>
            </div>
        </>
    )
}

export default Template1;