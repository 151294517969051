import React from "react";
import Routing from "../../Routing";

function Body(){


    return(
         
        <>

        <Routing></Routing>
        
        </>
    
    );


}

export default  React.memo(Body);