
import  ImageSub6  from '../assets/images/icons/shopping-cart.svg';
import  ImageSub7  from '../assets/images/icons/grid1.svg';
import  ImageSub8  from '../assets/images/icons/speed.svg';
import  ImageSub9  from '../assets/images/wealth-management-app.webp';
import  ImageSub10  from '../assets/images/user-friendly-trading-app.webp';
import  ImageSub11  from '../assets/images/fastest-trading-app.webp';




const FinxImage =[
{   
    id:1,
    image:`${ImageSub9}`,
    title:'Wealth Store',
    icon:`${ImageSub6}`,
    description:'Nurture your financial freedom with endless possibilities',
    alt: 'Wealth Management App',
},
{
    id:2,
    image:`${ImageSub10}`,
    title:'Elegant UI & UX',
    icon:`${ImageSub7}`,
    description:'Aiming for an intuitive interface for seamless navigation.',
    alt: 'User Friendly Trading App',
},

{
    id:3,
    image:`${ImageSub11}`,
    title:'Speedy Execution',
    icon:`${ImageSub8}`,
    description:"Built to deliver a blazing fast experience without hiccups.",
    alt: 'Fastest Trading App',
}

]
export default FinxImage