// urlConfig.js
export const StickeyFormURLs = [
    {
        path: "/",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/open-free-demat-account",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/open-brokerage-account",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/campaign/free-amc-demat-account",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/brokerage-charges",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/open-trading-account",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/equity-stock-trading",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/currency-forex-trading",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/refer-and-earn",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/research-listing",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/best-stocks-to-buy",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/best-short-term-stocks-to-buy",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/best-stocks-for-long-term-investment",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/best-intraday-stocks-to-buy",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Cut Your Intraday Trade Costs",
        pText: `With Intraday Research Calls, Upto 4X Leverage*,<br/> Low Brokerage + ₹0 Auto Square-off Charges`,
    },
    {
        path: "/intraday-charges",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Cut Your Intraday Trade Costs",
        pText: `With Intraday Research Calls, Upto 4X Leverage*,<br/> Low Brokerage + ₹0 Auto Square-off Charges`,
    },
    {
        path: "/upcoming-agm",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/upcoming-board-meeting",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/upcoming-bonus-shares",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/upcoming-dividend-paying-stocks",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/upcoming-stock-splits",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/upcoming-rights-issue",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/emi-calculator",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/stock-market-holidays",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/nse-holidays",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/bse-holidays",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/mcx-ncdex-holidays",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/margin-calculator",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/forex-margin-calculator",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    
   

    {
        path: "/brokerage-calculator",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    {
        path: "/hindi/trading-account",
        btntext:"अपना मुफ्त खाता खोलें",
        h3TextMini: " चॉइस डिमैट खाता खोलें: <br/>",
        h3Textmax: "शून्य शुल्क के साथ निवेश करें",
        pText: `₹0 खाता खोलने की फीस, पहले साल के लिए ₹0 AMC, <br/>₹0 ऑटो स्क्वायर-ऑफ चार्जेस + मुफ्त टूल्स।`,
    },
    {
        path: "/marathi/open-demat-account",
        btntext:"तुमचे खाते मोफत उघडा",
        h3TextMini: " चॉईस डिमॅट खाते उघडा: <br/>",
        h3Textmax: "शून्य शुल्कांसह गुंतवणूक करा",
        pText: `₹0 खाता उघडण्याची फी, पहिल्या वर्षासाठी ₹0 AMC,, <br/>₹0 ऑटो स्क्वेअर-ऑफ शुल्क + मोफत टूल्स`,
    },
    {
        path: "/hindi/open-demat-trading-account",
        btntext:"अपना मुफ्त खाता खोलें",
        h3TextMini: " चॉइस डिमैट खाता खोलें:<br/> ",
        h3Textmax: "शून्य शुल्क के साथ निवेश करें",
        pText: `₹0 खाता खोलने की फीस, पहले साल के लिए ₹0 AMC, <br/>₹0 ऑटो स्क्वायर-ऑफ चार्जेस + मुफ्त टूल्स।`,
    },
    {
        path: "/corporate-demat-account",
        btntext:"Open Free Corporate Account",
        h3TextMini: "Choice Corporate Demat Account:<br/> ",
        h3Textmax: "Streamline Your Investments",
        pText: `With ₹0 AMC for 1st Year, Low Brokerage, <br/>All-in-One Trading App + Compliance Support. `,
    },
    {
        path: "/ipo-investment-account",
        btntext:"Open Free IPO Account",
        h3TextMini: "Open Choice IPO Account: <br/> ",
        h3Textmax: "Don’t Miss India’s Top IPOs",
        pText: `Now Pre-Apply IPOs with IPO Reports,  <br/>Expert IPO Analysis + Discounted Price. `,
    },
    {
        path: "/upcoming-ipo",
        btntext:"Open Free IPO Account",
        h3TextMini: "Open Choice IPO Account: <br/> ",
        h3Textmax: "Don’t Miss India’s Top IPOs",
        pText: `Now Pre-Apply IPOs with IPO Reports,  <br/>Expert IPO Analysis + Discounted Price. `,
    },
    {
        path: "/minor-demat-account",
        btntext:"Open Free Demat Account",
        h3TextMini: "Choice Minor Demat Account:<br/> ",
        h3Textmax: "Plan Your Child’s Future Today",
        pText: `With Equity Baskets, ₹0 Account Opening Fee,  <br/>₹0 AMC for 1st Year + Free Expert Research.`,
    },
    {
        path: "/nri-demat-account",
        btntext:" Open Free NRI Account",
        h3TextMini: "Choice NRI Demat Account:<br/> ",
        h3Textmax: "Invest in India’s Markets Easily",
        pText: `With Free Demat Account, Multi-Asset Class,  <br/>Investments, Research Calls + Low DP Charges.`,
    },
    {
        path: "/commodity-trading",
        btntext:" Open Free Trading Account",
        h3TextMini: "Open Choice Trading Account:<br/> ",
        h3Textmax: "Diversify Your Portfolio Today",
        pText: `With Commodity F&O Strategies, Expert Support,  <br/>₹0 Account Opening Fee & ₹0 AMC for 1st Year.`,
    },
    {
        path: "/commodity-margin-calculator",
        btntext:" Open Free Trading Account",
        h3TextMini: "Open Choice Trading Account:<br/> ",
        h3Textmax: "Diversify Your Portfolio Today",
        pText: `With Commodity F&O Strategies, Expert Support,  <br/>₹0 Account Opening Fee & ₹0 AMC for 1st Year.`,
    },
    {
        path: "/derivatives-trading",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Reduce Your F&O Margin",
        pText: `With MTF, Daily Expert Calls, Pre-Built Option,  <br/>Strategies & Advance Option Chain.`,
    },
    {
        path: "/campaign/futures-options-trading",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Reduce Your F&O Margin",
        pText: `With MTF, Daily Expert Calls, Pre-Built Option,  <br/>Strategies & Advance Option Chain.`,
    },
    {
        path: "/futures-and-options-margin-calculator",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Reduce Your F&O Margin",
        pText: `With MTF, Daily Expert Calls, Pre-Built Option,  <br/>Strategies & Advance Option Chain.`,
    },
    {
        path: "/oi-spurts",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Reduce Your F&O Margin",
        pText: `With MTF, Daily Expert Calls, Pre-Built Option,  <br/>Strategies & Advance Option Chain.`,
    },
    {
        path: "/sumeet-bagadia",
        btntext:" Open Free Demat Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Trade with Award-Winning Analyst",
        pText: `Trading Calls, Research Reports, Expert Chat +,  <br/>Live Trading Sessions by Sumeet Bagadia.`,
    },
    {
        path: "/mutual-funds-investment",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice MF Account:<br/> ",
        h3Textmax: "Access India’s Top Mutual Funds",
        pText: `Leverage 2500+ Mutual Funds, Readymade ,  <br/>Baskets, Expert-rated MF Schemes & SIPs.`,
    },
    {
        path: "/mutual-fund-calculator",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice MF Account:<br/> ",
        h3Textmax: "Access India’s Top Mutual Funds",
        pText: `Leverage 2500+ Mutual Funds, Readymade ,  <br/>Baskets, Expert-rated MF Schemes & SIPs.`,
    },
    {
        path: "/mutual-fund-app",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice MF Account:<br/> ",
        h3Textmax: "Access India’s Top Mutual Funds",
        pText: `Leverage 2500+ Mutual Funds, Readymade ,  <br/>Baskets, Expert-rated MF Schemes & SIPs.`,
    },
    {
        path: "/sip-calculator",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice MF Account:<br/> ",
        h3Textmax: "Access India’s Top Mutual Funds",
        pText: `Leverage 2500+ Mutual Funds, Readymade ,  <br/>Baskets, Expert-rated MF Schemes & SIPs.`,
    },
    {
        path: "/amc",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice MF Account:<br/> ",
        h3Textmax: "Access India’s Top Mutual Funds",
        pText: `Leverage 2500+ Mutual Funds, Readymade ,  <br/>Baskets, Expert-rated MF Schemes & SIPs.`,
    },
    {
        path: "/top-funds",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice MF Account:<br/> ",
        h3Textmax: "Access India’s Top Mutual Funds",
        pText: `Leverage 2500+ Mutual Funds, Readymade ,  <br/>Baskets, Expert-rated MF Schemes & SIPs.`,
    },
    {
        path: "/amc",
        btntext:" Open Your Free Account",
        h3TextMini: "Open Choice MF Account:<br/> ",
        h3Textmax: "Access India’s Top Mutual Funds",
        pText: `Leverage 2500+ Mutual Funds, Readymade ,  <br/>Baskets, Expert-rated MF Schemes & SIPs.`,
    },
    {
        path: "/blog/:id",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account:<br/> ",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
        mfh3TextMini: "Open Choice MF Account:<br/> ",
        mfh3Textmax: "Access India’s Top Mutual Funds",
        mfpText: `Leverage 2500+ Mutual Funds, Readymade ,  <br/>Baskets, Expert-rated MF Schemes & SIPs.`,
    },
    {
        path: "/default",
        btntext:"Open Your Free Account",
        h3TextMini: "Open Choice Demat Account: <br/>",
        h3Textmax: "Invest with ZERO Fees",
        pText: `₹0 Account Opening, ₹0 AMC for 1st Year,<br/> ₹0 Auto Square-off Charges + Free Tools.`,
    },
    
];
