import './template6.scss';


function Template6(){
    return (
        <div>
        <section className='template-child-11'>
    
    <div className='template-child4-img'>

</div>
<div className='template-child4-img'>

</div>


</section>
<section className='template-child-11'>
    
    <div className='template-child4-img'>

</div>
<div className='template-child4-img'>

</div>


</section>
</div>

    )
}

export default Template6