import "../../assets/css/footer.scss"
import ImageFooter0 from '../../assets/images/choice-logo-white.svg';
import React from "react";
import LazyLoader from '../Common-features/LazyLoader';
import { NavLink } from "react-router-dom";
function Footer() {

  return (
    <>
      <div className="Footer">
        <footer className="main-footer">
          {/* <div className="footer-top-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="top-sec-sub">
                    <div className="footer-logo">
                      <NavLink to="/"><LazyLoader src={ImageFooter0} className={"img-fluid"} width={"193"} height={"49"} alt="Loading" /></NavLink>
                    </div>
                  </div>
                  <div className="top-sec-quick-links">
                    <div className="row">
                      <div className="col-md-7">
                        
                      </div>
                      <div className="col-md-5">

                      </div>
                    </div>
                  </div>
                  <div className="top-social-extra-links">
                    <div className="row align-items-center">
                      <div className="col-md-7">
                        
                      </div>
                      <div className="col-md-5">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="article">
                    <p><strong>Choiceinternational. CIN - L67190MH1993PLC071117</strong><br />
                      <strong>Choice Equity Broking Private Limited</strong>: SEBI Reg No. Broking - INZ000160131 ( BSE - 3299 )  | ( NSE - 13773 ) | ( MSEI - 73200 ) | ( MCX - 40585 ) | ( NCDEX - 01006 ). <br /> Depository Participant SEBI Reg. No. - IN - DP - 84 - 2015 , DP ID CDSL - 12066900 , NSDL ID - IN301895. Research Analyst - INH000000222<br />
                      <strong>Choice Wealth Private Limited</strong>: AMFI - Registered Mutual Fund Distributor. Association of Mutual Funds in India Registration Number - ARN - 78908. <br /> Pension Fund Regulatory and Development Authority (PFRDA) - POPSE52022022 | Affiliated with POP HDFC Pension Management Company.<br />
                      <strong>Choice Finserv Private Limited</strong>: NBFC Registration Number : N - 13.02216 <br />
                    </p>
                    <p>
                      <strong> Choice Insurance Broking Private Limited</strong>: IRDAI License No: 167, License Valid Till: 29-05-2024
                      | Category : Direct ( Life &amp; General )<br />
                      Registered Office:  Choice International Limited, Sunil Patodia Tower, J B Nagar, Andheri East, Mumbai, Maharashtra 400099.<br />
                      For any Grievances / Queries email at <a href="mailto:info@choiceindia.com">ig@choiceindia.com</a> &amp; <a href="mailto:care@choiceindia.com">care@choiceindia.com</a> | Online Dispute Resolution Link: <a href="https://smartodr.in/login" target="_blank">https://smartodr.in/login</a></p>

                    <p>Cautionary Message :
                      <ol style={{ marginleft: "-1rem" }} className="ol-p0">
                        <li>Sharing of trading credentials – login id &amp; passwords including OTP’s:- Keep Your Password/Pin and OTP’s private &amp; confidential to avoid any misuse or unauthorised trades. Please ensure that you do not share it with any one.</li>
                        <li>Trading in leveraged products like options without proper understanding, which could lead to losses</li>
                        <li>Writing/ selling options or trading in option strategies based on tips, without basic knowledge &amp; understanding of the product and its risks</li>
                        <li>Dealing in unsolicited tips through Whatsapp, Telegram, YouTube, Facebook, SMS, calls, etc.</li>
                        <li>Trading in “Options” based on recommendations from unauthorised / unregistered investment advisors and influencers</li>
                      </ol>
                    </p>

                    <p>   Disclaimer: <br />
                      1. *Investments in securities market are subject to market risks, read all the related documents carefully before investing.<br />
                      2. In addition to client based business, we are also doing proprietary trading. <br />
                      3. Brokerage will not exceed the SEBI prescribed limit.</p>

                    <p>  <strong>Research Disclaimer and Disclosure inter-alia as required under Securities and Exchange Board of India (Research Analysts) Regulations, 2014</strong></p>
                    <p>  Choice  Equity  Broking  Private  Limited  (“CEBPL”)  is  a  registered  Research  Analyst  Entity  (Reg.  No.  INH000000222  )  (hereinafter    be  referred  as  “CEBPL”).  (CIN.    NO.: U65999MH2010PTC198714).</p>
                    <p> <strong>Reg. Address:</strong> Sunil Patodia Tower, J B Nagar, Andheri(East), Mumbai 400099. Tel. No. 022-6707 9999 . </p>
                    <p><strong>Compliance Officer:</strong> Mr. Prashant Salian. Tel.  022-67079999
                      - Ext-2310<br />
                      Email- Compliance@choiceindia.com</p>
                    <p><strong>Grievance officer:</strong> Deepika Singhvi  Tel.022-67079999- Ext-834. <br />
                      Email-  ig@choiceindia.com</p>
                    <p><strong>Research Disclaimer:</strong> Investment in the securities market is subject to market risks. Read all the related documents carefully before investing. Registration granted by SEBI, and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors.</p>

                    <p>&copy; Choice International Limited. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>

  );
}

export default Footer;

